/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyRead } from './companyRead';


export interface VPNRead { 
    company?: CompanyRead;
    readonly createdAt?: string;
    readonly id?: string;
    ipAddress: string;
    status?: VPNRead.StatusEnum;
    type: VPNRead.TypeEnum;
    readonly updatedAt?: string;
    vpnNode?: string | null;
}
export namespace VPNRead {
    export type StatusEnum = 'ACTIVE' | 'SUSPENDED' | 'ASSIGNED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Suspended: 'SUSPENDED' as StatusEnum,
        Assigned: 'ASSIGNED' as StatusEnum
    };
    export type TypeEnum = 'VPNRouter' | 'VPNDevice';
    export const TypeEnum = {
        VpnRouter: 'VPNRouter' as TypeEnum,
        VpnDevice: 'VPNDevice' as TypeEnum
    };
}


