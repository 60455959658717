import {Component} from '@angular/core';
import { Router } from '@angular/router';
import { Platform} from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { ApiService } from './services/api/core/api.service';
import { LanguageService } from './services/language/language.service';
import { AuthService } from './services/api/core/auth.service';
import { Store } from '@ngrx/store';
import * as AuthActions from '@app/shared/store/user/user.actions';
import {selectLoading, selectSystemLoading} from '@app/shared/store/user/user.reducer';
import {Actions, ofType} from "@ngrx/effects";
import {loginRedirect, setLoader} from "@app/shared/store/user/user.actions";
import {RolesKeywords} from "@app/shared/store/user/utils/interfaces";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  subscriptions: Subscription[] = [];
  initialized = false;

  public readonly sessionLoading$: Observable<boolean> = this.store.select(selectLoading);;
  public readonly systemLoading$: Observable<boolean> = this.store.select(selectSystemLoading);

  constructor(
    private platform: Platform,
    private language: LanguageService,
    private api: ApiService,
    private router: Router,
    private readonly authApiService: AuthService,
    private readonly store: Store,
    private readonly actions$: Actions
  ) {
    this.getStoredTokens();
    this.initializeApp();

    this.actions$.pipe(ofType(loginRedirect)).subscribe(async ({role}) => {
      switch (role) {
        case RolesKeywords.Finance:
          await this.router.navigateByUrl('/dashboard/account/information');
          break;
        default:
          await this.router.navigateByUrl('/dashboard/overview');
          break;
      }

      this.store.dispatch(setLoader({
        data: false
      }))
    })
  }

  private async getStoredTokens(): Promise<void> {
    const tokens = await this.authApiService.getTokensFromStorage();
    this.store.dispatch(AuthActions.setLoader({ data: true }));

    if (tokens) {
      this.store.dispatch(
          AuthActions.signInSuccess({
            data: tokens,
            redirection: false,
          }))
    } else {
      this.store.dispatch(AuthActions.setLoader({ data: false }))
    }

  }

  initializeApp(): void {
    this.platform.ready().then(() => {
      this.language.setInitialAppLanguage();
      this.initialized = true;
    });
  }
}
