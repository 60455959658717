import { createFeature, createReducer, on } from '@ngrx/store';

import * as AuthActions from './user.actions';
import { AccountRead } from '@app/shared/openapi';

export const authFeatureKey = 'auth';

export interface State {
  access: string;
  refresh: string;
  account: AccountRead;
  loading: boolean;
  systemLoading: boolean;
  error: Error;
}

export const initialState: State = {
  access: null,
  refresh: null,
  account: null,
  loading: false,
  systemLoading: false,
  error: null,
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.signIn, (state) => ({
    ...state,
    loading: true,
  })),
  on(AuthActions.signInSuccess, (state, action) => ({
    ...state,
    access: action.data?.['access'],
    refresh: action.data?.['refresh'],
  })),
  on(AuthActions.signInFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  on(AuthActions.setSystemLoader, (state, action) => ({
    ...state,
    systemLoading: action.data,
  })),
  on(AuthActions.setLoader, (state, action) => ({
    ...state,
    loading: action.data,
  })),
  on(AuthActions.getSelfUserSuccess, (state, action) => ({
    ...state,
    account: action.data,
  })),
  on(AuthActions.updateUserInfo, (state, action) => ({
    ...state,
    account: {
      ...state.account,
      user: {
        ...state.account.user,
        ...action.data,
      },
    },
  })),
  on(AuthActions.clearSession, () => initialState),
);

export const authFeature = createFeature({
  name: authFeatureKey,
  reducer: authReducer,
});

export const { name, reducer, selectAccess, selectRefresh, selectLoading, selectAccount, selectAuthState, selectSystemLoading } =
  authFeature;
